 <template>
    <div class="bg-gpl-blue/90">
        <div class="w-full xl:w-5/6 xl:mx-auto py-10">
            <div class="grid grid-cols-6">
                    <!-- <div class="w-1/3">
                        <img  class="h-4/5 lg:h-full px-5 xl:px-0 float-left" :src="require('@/assets/imgs/truck.png')" alt="Green Planet Logistics - 600+ veicoli" />
                    </div>
                    <div class="w-2/3">
                         <h3 class="text-5xl lg:text-6xl 2xl:text-8xl text-white font-black uppercase text-left">600+</h3>
                        <h3 class="text-2xl lg:text-3xl 2xl:text-4xl text-white font-black uppercase text-left">veicoli</h3>
                    </div> -->
                    <StatButton
                        class="col-span-full md:col-span-3 md:col-start-3 xl:col-start-1 2xl:col-span-2 2xl:col-start-2 mt-5 flex flex-row"
                        quantity="700+"
                        :step="10"
                        brief="veicoli"
                        :icon="require('@/assets/imgs/truck.png')"
                    ></StatButton>
                    <StatButton
                        class="col-span-full md:col-span-3 md:col-start-3 xl:col-start-4 2xl:col-span-2 mt-5 flex flex-row"
                        quantity="40+"
                        brief="infrastrutture"
                        :icon="require('@/assets/imgs/warehouse.png')"
                    >
                        <template v-slot:um>
                            <span class="text-3xl lg:text-4xl 2xl:text-6xl">Ettari</span>
                        </template>
                    </StatButton>
                    <StatButton
                        class="col-span-full md:col-span-3 md:col-start-3 xl:col-start-1 2xl:col-span-2 2xl:col-start-2 mt-5 flex flex-row"
                        quantity="400+"
                        :step="10"
                        brief="autisti"
                        :icon="require('@/assets/imgs/people.png')"
                    ></StatButton>
                    <StatButton
                        class="col-span-full md:col-span-3 md:col-start-3 xl:col-start-4 2xl:col-span-2 mt-5 flex flex-row"
                        quantity="100+"
                        brief="fatturati nel 2022"
                        :icon="require('@/assets/imgs/trend.png')"
                    >
                        <template v-slot:um>
                            <span class="text-3xl lg:text-4xl 2xl:text-6xl">mln &euro;</span>
                        </template>
                    </StatButton>
                <!-- <div class="col-span-full md:col-span-3 md:col-start-3 xl:col-start-1 2xl:col-span-2 2xl:col-start-2 mt-5 flex flex-row">
                    <div class="w-1/3">
                        <img  class="h-4/5 lg:h-full px-5 xl:px-0 float-left" :src="require('@/assets/imgs/people.png')" alt="Green Planet Logistics - 260+ autisti" />
                    </div>
                    <div class="w-2/3">
                        <h3 class="text-5xl lg:text-6xl 2xl:text-8xl text-white font-black uppercase text-left">260+</h3>
                        <h3 class="text-2xl lg:text-3xl 2xl:text-4xl text-white font-black uppercase text-left">autisti</h3>
                    </div>
                </div> -->
                <!-- <div class="col-span-full md:col-span-3 md:col-start-3 xl:col-start-4 2xl:col-span-2 mt-5 flex flex-row">
                    <div class="w-1/3">
                        <img  class="h-4/5 lg:h-full px-5 xl:px-0 float-left" :src="" alt="Green Planet Logistics - 54+ milioni di euro fatturati nel 2023" />
                    </div>
                    <div class="w-2/3">
                        <h3 class="text-5xl lg:text-6xl 2xl:text-8xl text-white font-black uppercase text-left">54+ </h3>
                        <h3 class="text-2xl lg:text-3xl 2xl:text-4xl text-white font-black uppercase text-left">fatturati nel 2022</h3>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import StatButton from './StatButton.vue';

export default {
    name: 'StatsSection',
    components: {
        StatButton,        
    },
};
</script>

const NEWS = [
    {
        title: 'La parola d\'ordine: CONCRETEZZA!',
        brief: 'Nella giornata di giovedì 29 febbraio 2024 si è tenuta con successo la seconda convention di Green Planet Logistics, nella sede della Lanzi Trasporti presso l’Interporto di Parma, un incontro ricco di spunti e di proposte operative, volto a rafforzare la collaborazione fra i componenti della Rete.',
        // mainImg: '/imgs/news/logisticamente_it_green_planet_logistics.jpg',
        mainImg: 'gpl/news/gp-news-01.jpeg',
        mainImgAlt: 'green-planet-logistics-la-parola-d-ordine-concretezza',
        keys: ['Green Planet Logistics', 'Rete di imprese', 'Logistica Sostenibile'],
        // source: {
        //     link: 'https://www.logisticamente.it/Articoli/15216/green-planet-logistics-espansione-e-sostenibilita-nel-settore-logistico-intermodale/',
        //     name: 'Logisticamente.it',
        // },
        date: '29 Febbraio 2024',
        slug: 'green-planet-logistics-espansione-e-sostenibilita-nel-settore-logistico-intermodale',
        content: '/news/20240229_internal.html',
    },
    {
        title: 'Green Planet Logistics: espansione e sostenibilità nel settore logistico intermodale',
        brief: 'A meno di un anno dalla sua fondazione, il network di Green Planet Logistics si rafforza e si propone di offrire servizi di trasporto e logistica sostenibili per conto terzi (LP3). L\'offerta copre tutto il processo, dalla gestione dei depositi alla consegna, dal trasporto al magazzinaggio, dall\'organizzazione degli ordini all\'imballaggio e alle spedizioni.',
        // mainImg: '/imgs/news/logisticamente_it_green_planet_logistics.jpg',
        mainImg: 'gpl/news/logisticamente_it_green_planet_logistics.jpg',
        mainImgAlt: 'green-planet-logistics-espansione-e-sostenibilita-nel-settore-logistico-intermodale',
        keys: ['Green Planet Logistics', 'Rete di imprese', 'Logistica Sostenibile'],
        source: {
            link: 'https://www.logisticamente.it/Articoli/15216/green-planet-logistics-espansione-e-sostenibilita-nel-settore-logistico-intermodale/',
            name: 'Logisticamente.it',
        },
        date: '14 Aprile 2023',
        slug: 'green-planet-logistics-espansione-e-sostenibilita-nel-settore-logistico-intermodale',
        content: '/news/20230414_logisticamente_it.html',
    },
    {
        title: 'Green Planet Logistics accoglie due soci "di ferro" e uno "eccezionale"',
        brief: 'Green Planet Logistics, nata in Italia con l\'idea di formare una rete tra imprese italiane di trasporto e logistica attente alla qualità e alla sostenibilità ambientale, ha accolto nella sua compagine diversi nuovi soci con i quali potrà allargare la portata delle sue iniziative.',
        mainImg: 'gpl/news/supply_chain_it.jpg',
        mainImgAlt: 'green-planet-logistics-accoglie-due-nuovi-soci-di-ferro-e-uno-eccezionale',
        keys: ['Green Planet Logistics', 'Rete di imprese', 'Nuovi ingessi'],
        source: {
            link: 'https://www.supplychainitaly.it/2023/04/07/green-planet-logistics-accoglie-due-soci-di-ferro-e-uno-eccezionale/',
            name: 'supplychainitaly.it',
        },
        date: '07 Aprile 2023',
        slug: 'green-planet-logistics-accoglie-due-nuovi-soci-di-ferro-e-uno-eccezionale',
        content: '/news/20230407_supply_chain_it.html',
    },
    {
        title: 'Green Planet Logistics: la rete diventa di ferro',
        brief: 'Con l’ingresso ufficiale nella Rete di due altri storici operatori dei trasporti intermodali come la IORIO TRASPORTI E LOGISTICA SPA di Napoli e LANZI TRASPORTI SRL di Parma, parte la seconda fase del progetto di Green Planet Logistics, la Rete di Imprese voluta da cinque imprenditori italiani per affrontare insieme i rapidi cambiamenti della Logistica.',
        mainImg: 'gpl/news/gpl_trasportonline.jpeg',
        mainImgAlt: 'green-planet-logistics-la-rete-diventa-di-ferro',
        keys: ['Green Planet Logistics', 'Rete di imprese'],
        source: {
            link: 'https://www.transportonline.com/notizia_56259_Green-Planet-Logistics:-la-rete-diventa-di-ferro.html',
            name: 'trasportonline.com',
        },
        date: '05 Aprile 2023',
        slug: 'green-planet-logistics-la-rete-diventa-di-ferro',
        content: '/news/20230405_trasportonline_it.html',
    },
    {
        title: 'Cresce la rete di Green Planet Logistics',
        brief: 'Con l’ingresso ufficiale nella Rete di due altri storici operatori dei trasporti intermodali come la IORIO TRASPORTI E LOGISTICA SPA di Napoli e LANZI TRASPORTI SRL di Parma, parte la seconda fase di Green Planet Logistics, la Rete di Imprese voluta da cinque imprenditori italiani per affrontare insieme i rapidi cambiamenti della Logistica.',
        mainImg: 'gpl/news/news_4.jpg',
        mainImgAlt: 'cresce-la-rete-di-green-planet-logistics',
        keys: ['Green Planet Logistics', 'Rete di imprese'],
        source: {
            link: 'https://trasportale.it/green-planet-logistics-rete/',
            name: 'trasportale.it',
        },
        date: '05 Aprile 2023',
        slug: 'cresce-la-rete-di-green-planet-logistics',
        content: '/news/20230405_trasportale_it.html',
    },
    {
        title: 'E vai col ferro. La rete di Green Planet Logistics si rafforza nelle attività intermodali',
        brief: 'Il network delle imprese nato nel 2022 per volontà di cinque realtà storiche della logistica si allarga. Oltre a BS Group di Teramo, Palladino Logistics di Avellino, So.Log di Tirano (Sondrio), Trasporti Fratelli Primiceri di Casarano (Lecce) e Trial di Sesto San Giovanni (Milano), ora entrano a far parte altri due altri storici operatori dei trasporti intermodali come la Iorio di Napoli e la Lanzi di Parma',
        mainImg: 'gpl/news/uomini_e_trasporti_it.jpg',
        mainImgAlt: 'e-vai-col-ferro-la-rete-di-green-planet-logistics-si-rafforza-nelle-attivita-intermodali',
        keys: ['Green Planet Logistics', 'Rete di imprese', 'Trasporto Intermodale'],
        source: {
            link: 'https://www.uominietrasporti.it/professione/logistica/e-vai-col-ferro-la-rete-di-green-planet-logistics-si-rafforza-nelle-attivita-intermodali/',
            name: 'uominietrasporti.it',
        },
        date: '04 Aprile 2023',
        slug: 'e-vai-col-ferro-la-rete-di-green-planet-logistics-si-rafforza-nelle-attivita-intermodali',
        content: '/news/20230404_uomini_e_trasporti_it.html',
    },
    {
        title: 'Reti d’impresa: la nuova mappa italiana',
        brief: 'Il contratto di rete permette alle imprese di rafforzare la governance per affrontare le attuali incertezze di mercato e le sfide più innovative.',
        mainImg: 'gpl/news/pmi_it.webp',
        mainImgAlt: 'reti-di-impresa-la-nuova-mappa-italiana',
        keys: ['Green Planet Logistics', 'Rete di impresa'],
        source: {
            link: 'https://www.pmi.it/impresa/business-e-project-management/406972/reti-dimpresa-la-nuova-mappa-italiana.html',
            name: 'pmi.it',
        },
        date: '22 Marzo 2023',
        slug: 'reti-di-impresa-la-nuova-mappa-italiana',
        content: '/news/20230322_pmi_it.html',
    },
    {
        title: 'Freight Leaders Council annuncia l’ingresso di quattro nuovi soci',
        brief: 'Boni SpA, Studio Zunarelli, Green Planet Logistics e Cromwell Property Group Italy si uniscono alla comunità leader del settore logistico.',
        mainImg: 'gpl/news/flc_trasportonline.jpg',
        mainImgAlt: 'freight-leaders-council-annuncia-l-ingresso-di-quattro-nuovi-soci',
        keys: ['Green Planet Logistics', 'Freight Leaders Council'],
        source: {
            link: 'https://www.transportonline.com/notizia_56149_Freight-Leaders-Council-annuncia-l%E2%80%99ingresso-di-quattro-nuovi-soci-.html',
            name: 'trasportonline.it',
        },
        date: '21 Marzo 2023',
        slug: 'freight-leaders-council-annuncia-l-ingresso-di-quattro-nuovi-soci',
        content: '/news/20230321_trasportonline_it.html',
    },
    {
        title: 'Logistica, il Freight Leaders Council annuncia l\'ingresso di quattro nuovi soci',
        brief: 'Continua il processo di sviluppo del FLC verso una sempre maggiore rappresentatività dell\'intera filiera industriale integrata logistica-trasporti. L\'obiettivo è quello di contribuire alla crescita economica del Paese, che passa inevitabilmente attraverso un processo di rafforzamento degli attori principali della logistica, sempre più inseriti nella catena del valore industriale',
        mainImg: 'gpl/news/flc_council.png',
        mainImgAlt: 'logistica-il-freight-leaders-council-annuncia-l-ingresso-di-quattro-nuovi-soci',
        keys: ['Green Planet Logistics', 'Freight Leaders Council'],
        source: {
            link: 'https://www.ferrovie.it/portale/articoli/13208',
            name: 'ferrovie.it',
        },
        date: '21 Marzo 2023',
        slug: 'logistica-il-freight-leaders-council-annuncia-l-ingresso-di-quattro-nuovi-soci',
        content: '/news/20230321_ferrovie_it.html',
    },
    {
        title: "Green Planet Logistic, una nuova rete di imprese italiane per la logistica del futuro",
        brief: "Nasce Green Planet Logistic, la rete di imprese italiane di trasporto e logistica che guarda alla sostenibilità. L' attenzione all'ambiente e agli standard di qualità spingono il nuovo network, che può contare su 650 mezzi di proprietà.",
        mainImg: 'gpl/news/news_3.png',
        mainImgAlt: 'nasce-green-planet-logistics-la-prima-rete-di-imprese-italiane-di-trasporto-e-logistica',
        keys: ['Green Planet Logistics', 'Rete di imprese'],
        source: {
            link: 'https://blog.tuttocarrellielevatori.it/47964/green-planet-logistic-una-nuova-rete-di-imprese-italiane-per-la-logistica-del-futuro/',
            name: 'tuttocarrellielevatori.com',
        },
        date: '08 Settembre 2022',
        slug: 'green-planet-logistics-una-nuova-rete-di-imprese-italiane',
        content: '/news/20220908_tuttocarrellielevatori_it.html',
    },
    {
        title: 'Green Planet Logistics, la rete d’impresa che rende più sostenibili trasporti e logistica',
        brief: 'BS Group di Teramo, Palladino Logistics di Avellino, So.Log di Tirano (Sondrio), Trasporti Fratelli Primiceri di Casarano (Lecce) e Trial di Sesto San Giovanni (Milano): sono queste le aziende di trasporto e logistica che hanno dato vita a una nuova importante iniziativa nel settore: la creazione di una  rete d’imprese, battezzata Green Planet Logistics, dedicata ai servizi di trasporto e logistica 3PL, ovvero con l’esternalizzazione di ogni servizio, dal deposito, fino alla consegna.',
        mainImg: 'gpl/news/news_4.jpg',
        mainImgAlt: 'nasce-green-planet-logistics-la-prima-rete-di-imprese-italiane-di-trasporto-e-logistica',
        keys: ['Green Planet Logistics', 'Rete di imprese'],
        source: {
            link: 'https://stradafacendo.tgcom24.it/2022/08/13/green-planet-logistics-la-rete-dimpresa-che-rende-piu-sostenibili-trasporti-e-logistica/',
            name: 'TGCOM24 StradaFacendo',
        },
        date: '13 agosto 2022',
        slug: 'green-planet-logistics-la-rete-di-impresa-sostenibile',
        content: '/news/20220813_stradafacendo_tgcom24_it.html',
    },
    {
        title: 'Nasce una rete d’imprese di trasporto e logistica',
        brief: 'Il 5 agosto 2022 è stata comunicata la nascita della rete d’imprese Green Planet Logistics, dedicata ai servizi di trasporto e logistica 3PL, con attenzione alla sostenibilità ambientale. Il consiglio direttivo dei primi tre anni è formato da cinque imprese: BS Group di Teramo, Palladino Logistics di Avellino, So.Log di Tirano (Sondrio), Trasporti Fratellilli Primiceri di Casarano (Lecce) e Trial di Sesto San Giovanni (Milano)',
        mainImg: 'gpl/news/news_1.jpg',
        mainImgAlt: 'nasce-una-rete-di-imprese-di-trasporto-e-logistica',
        keys: ['Green Planet Logistics', 'Rete di imprese'],
        source: {
            link: 'https://www.trasportoeuropa.it/notizie/logistica/nasce-una-rete-dimprese-di-trasporto-e-logistica/',
            name: 'trasportoeuropa.it',
        },
        date: '08 Agosto 2022',
        slug: 'nasce-una-rete-di-imprese-di-trasporto-e-logistica',
        content: '/news/20220808_trasportoeuropa_it.html',
    },
    {
        title: 'Nasce Green Planet Logistics, la prima rete di imprese italiane di trasporto e logistica',
        brief: 'Nasce "Green Planet Logistics" la prima rete di imprese italiane di trasporto e logistica, dedicata all’esecuzione dei servizi tipici del comparto 3PL, con particolare attenzione alla qualità ed alla sostenibilità ambientale.',
        mainImg: 'gpl/news/news_2.jpg',
        mainImgAlt: 'nasce-green-planet-logistics-la-prima-rete-di-imprese-italiane-di-trasporto-e-logistica',
        keys: ['Green Planet Logistics', 'Rete di imprese'],
        source: {
            link: 'https://www.transportonline.com/notizia_54658_Nasce-Green-Planet-Logistics,-la-prima-rete-di-imprese-italiane-di-trasporto-e-logistica-.html',
            name: 'trasportonilne.com',
        },
        date: '05 Agosto 2022',
        slug: 'nasce-green-planet-logistics-la-prima-rete-di-imprese-italiane-di-trasporto-e-logistica',
        content: '/news/20220805_trasportonline_it.html',
    },
];
 export default NEWS;

<template>
    <div class="w-full bg-gpl-blue mt-auto">
        <div class="w-full xl:w-5/6 flex flex-col xl:flex-row min-h-96 py-12">
            <div class="mx-auto order-3 xl:order-first my-3 xl:my-0 xl:mx-auto ">
                <!--Logo -->
                <div class="">
                    <!-- <img src="/imgs/gpl_logo_full-min.png" alt="Green Planet Logistics" class="h-20 mx-auto xl:h-18 xl:h-24 xl:mx-0"/> -->
                    <IKImage
                            class="h-20 mx-auto xl:h-18 xl:h-24 xl:mx-0"
                            path="gpl/graphics/gpl_logo_full.png"
                            :lqip="{active:true}"
                            width="300"
                            height="100"
                            alt="Green Planet Logistics - Logo"
                        />
                </div>
                <div class="mt-5">
                    <p class="text-left text-neutral-400 text-lg">
                        Green Planet Logistics - IT12522650964 <br/>
                        Viale Sabotino 19/2, 20135 Milano (MI)
                    </p>
                </div>
                <div class="mt-2 w-full text-left">
                    <span class="text-neutral-400 text-lg">Seguici su </span>
                    <a class="text-white text-lg mx-3" href="https://www.linkedin.com/company/92780778/" target="_blank">
                        <font-awesome-icon icon="fa-brands fa-linkedin" /> &nbsp; <span class="text-lg">Linkedin </span>
                    </a>
                    <!-- <a class="text-white text-2xl mx-3" href="/">
                        <font-awesome-icon icon="fa-brands fa-facebook" />
                    </a>
                    <a class="text-white text-2xl mx-3" href="/">
                        <font-awesome-icon icon="fa-brands fa-instagram" />
                    </a> -->
                </div>
            </div>

            <div class="mx-8 lg:w-fit xl:mx-auto my-4 xl:my-0 flex flex-col text-left">
                <h3 class="text-left text-2xl  text-gpl-green font-bold mb-2">Partners</h3>
                <a href="https://trasportiprimiceri.com" class="text-white text-lg" target="_blank">
                    <font-awesome-icon icon="fa-solid fa-link" /> &nbsp; Trasporti F.lli Primiceri
                </a>
                <a href="http://www.solog.it" class="text-white text-lg" target="_blank">
                    <font-awesome-icon icon="fa-solid fa-link" /> &nbsp; So.Log. .
                </a>
                <a href="http://palladinotrasporti.com" class="text-white text-lg" target="_blank">
                    <font-awesome-icon icon="fa-solid fa-link" /> &nbsp; Palladino Logistics
                </a>
                <a href="https://bs-group.it" class="text-white text-lg" target="_blank">
                    <font-awesome-icon icon="fa-solid fa-link" /> &nbsp; BS-Group
                </a>
                <a href="https://trialsrl.com" class="text-white text-lg" target="_blank">
                    <font-awesome-icon icon="fa-solid fa-link" /> &nbsp; Trial
                </a>
                <p class="text-white text-lg">
                    <font-awesome-icon icon="fa-solid fa-link" /> &nbsp; Iorio Trasporti e Logistica
                </p>
                <!-- <a href="https://cianciosigroup.com" class="text-white text-lg" target="_blank">
                    <font-awesome-icon icon="fa-solid fa-link" /> &nbsp; Cianciosi Group
                </a> -->
            </div>
            <!-- Temporary suspended-->
            <div class="ml-8 mr-auto my-4 xl:my-0  flex flex-col text-left">
                <h3 class="text-left text-2xl text-gpl-green font-bold mb-2">Links</h3>
                <router-link to="/downloads" class="text-white text-lg">
                    Downloads
                </router-link>
                <a href="/" class="text-white text-lg">
                    Privacy Policy
                </a>
                <a href="/" class="text-white text-lg">
                    Cookie Policy
                </a>
            </div>
            <div class=" w-fit mx-auto my-4 bg-white h-fit px-8 py-8 rounded-full xl:mr-12 mt-auto shadow-lg shadow-white/10">
                    <!-- <img src="/imgs/flc.png" alt="Green Planet Logistics" class="h-20 mx-auto xl:h-18 xl:h-28 xl:mx-auto"/> -->
                    <IKImage
                        class="h-20 mx-auto xl:h-18 xl:h-28 xl:mx-auto"
                        path="gpl/flc.png"
                        alt="Freight Leader Council"
                        :lqip="{active:true}"
                        width="110"
                        height="110"

                    />
            </div>
        </div>
    </div>
</template>

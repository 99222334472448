const COMPANIES = {
    'trasporti-flli-primiceri': {
        slug: 'trasporti-flli-primiceri',
        name: 'Trasporti F.lli Primiceri',
        place: 'Lecce',
        phone: '333 9321774',
        mail: 'info@trasportiprimiceri.com',
        pec: 'trasportiprimiceri@pec.it',
        website: 'www.trasportiprimiceri.it',
        fullAddress: 'SP 33, 73042 Casarano (Le)',
        logo: 'gpl/companies/primiceri.png',
        social: {
            linkedin: 'https://ke.linkedin.com/company/trasporti-f.lli-primiceri',
            instagram: 'https://www.instagram.com/trasporti_f.lli_primiceri/',
            facebook: 'https://www.facebook.com/primiceritrasporti',
            twitter: '',
        },
        quotation: {
            position: 'CEO',
            name: 'Sonia Primiceri',
            text: '"La vostra soddisfazione è la nostra destinazione"',
            picture: 'gpl/companies/sonia-primiceri.jpg',
            visible: true,
        },
        content: '/companies/primiceri.html',
        certifications: [
            'ISO9001.2015',
        ],
        services: [
            'Logistica',
            'Raccolta e distribuzione',
            'Trasporti Internazionali',
            'Trasporti Intermodali',
            'Servizio espresso su pedana',
            'Servizi di collettame',
            'Carichi completi',
        ],
        stats: {
            veichles: 87,
            drivers: 58,
            infrastructures: 3,
        },
        partners: [
            {
                img: 'gpl/companies/palletways.png',
                altImg: 'Palletways',
            },
            {
                img: 'gpl/companies/cds.png',
                altImg: 'Gruppo CDS',
            },
            {
                img: 'gpl/companies/lotras.png',
                altImg: 'Lotras',
            },
        ],
    },
    // 'cianciosi-trasporti-e-servizi-srl': {
    //     slug: 'cianciosi-trasporti-e-servizi-srl',
    //     name: 'Cianciosi Trasporti e Servizi',
    //     place: 'Chieti',
    //     phone: '+39 0873-547893',
    //     mail: 'commerciale@cianciosigroup.com',
    //     pec: 'trasportiprimiceri@pec.it',
    //     website: 'www.cianciosigroup.com',
    //     fullAddress: 'Via 5° Strada n.4, 66050 Furci (CH)',
    //     logo: 'gpl/companies/cianciosi.png',
    //     social: {
    //         linkedin: 'https://it.linkedin.com/in/cianciosigroup',
    //         instagram: '',
    //         facebook: 'https://www.facebook.com/Cianciosi-Autotrasporti-Eccezionali-115590905209832/',
    //         twitter: '',
    //     },
    //     quotation: {
    //         position: 'CEO',
    //         name: 'Marco Cianciosi',
    //         text: '"L’adesione alla Green Planet Logistics è stata fortemente voluta dalla ns società per rispondere insieme ai colleghi aderenti su più fronti in tutto il mercato. "',
    //         // picture: 'gpl/companies/marco-cianciosi.jpg',
    //         picture: 'gpl/user.png',
    //         visible: false,
    //     },
    //     content: '/companies/cianciosi.html',
    //     certifications: [
    //     ],
    //     services: [
    //         'Magazzino logistico per deposito merce in conto terzi ',
    //         'Furgoni dedicati',
    //         'Trasporti eccezionali',
    //         'Trasporti Adr Categoria 4-5',
    //     ],
    //     stats: {
    //         veichles: 40,
    //         drivers: 21,
    //         infrastructures: 3,
    //     },
    //     partners: [
    //     ],
    // },
    'bs-group-srl': {
        slug: 'bs-group-srl',
        name: 'BS-Group s.r.l.',
        place: 'Teramo',
        phone: '+39 0861.856236',
        mail: 'info@bs-group.it',
        pec: '',
        website: 'www.bs-group.it',
        fullAddress: 'Via Torino 235 63074 S.Benedetto del Tronto (AP)',
        logo: 'gpl/companies/bs-group.jpg',
        social: {
            linkedin: '',
            instagram: '',
            facebook: '',
        },
        quotation: {
            position: 'CEO',
            name: '',
            text: '""',
            picture: 'gpl/user.png',
            visible: false,
        },
        content: '/companies/bs-group.html',
        certifications: [],
        services: [
            'Logistica',
            'Raccolta e distribuzione',
            'Carichi completi',
            'Servizio espresso su pedana',
            'Servizi di collettame',
        ],
        stats: {
            veichles: 58,
            drivers: 50,
            infrastructures: 8,
        },
        partners: [
        ],
    },
    'trial-srl': {
        slug: 'trial-srl',
        name: 'Trial s.r.l.',
        place: 'Milano',
        phone: '333 9321774',
        mail: 'amministrazione@trialsrl',
        pec: 'trial@legalmail.it',
        website: 'www.trialsrl.com',
        fullAddress: 'Viale T. Edison, 637 - 20099 Sesto San Giovanni (MI)',
        logo: 'gpl/companies/trial.webp',
        social: {
            linkedin: '',
            instagram: '',
            facebook: '',
        },
        quotation: {
            position: 'CEO',
            name: '',
            text: '""',
            picture: 'gpl/user.png',
            visible: false,
        },
        content: '/companies/trial-srl.html',
        certifications: [
            'ISO 9001.2015',
            'Rating Legalità',
        ],
        services: [
            'Logistica',
            'Raccolta e distribuzione',
            'Trasporti Internazionali',
            'Carichi completi',
            'Servizio espresso su pedana',
            'Trasporti Speciali',
        ],
        stats: {
            veichles: 45,
            drivers: 15,
            infrastructures: 4,
        },
        partners: [
        ],
    },
    'palladino-logistics-srl': {
        slug: 'palladino-logistics-srl',
        name: 'Palladino Logistics s.r.l.',
        place: 'Avellino',
        phone: '+39 0825 610020',
        mail: '',
        pec: '',
        website: 'www.palladinotrasporti.com',
        fullAddress: 'Contrada Pianodardine, 83100 Avellino (AV)',
        logo: 'gpl/companies/palladino.jpeg',
        social: {
            linkedin: '',
            instagram: '',
            facebook: '',
        },
        quotation: {
            position: 'CEO',
            name: '',
            text: '""',
            picture: 'gpl/user.png',
            visible: false,
        },
        content: '/companies/palladino-logistics.html',
        certifications: [
            'ISO 9001.2015',
        ],
        services: [
            'Logistica',
            'Raccolta e distribuzione',
            'Trasporti Internazionali',
            'Carichi completi',
            'Servizio espresso su pedana',
        ],
        stats: {
            veichles: 232,
            drivers: 75,
            infrastructures: 3,
        },
        partners: [
        ],
    },
    'iorio-trasporti-e-logistica-spa': {
        slug: 'iorio-trasporti-e-logistica-spa',
        name: 'Iorio Trasporti e Logistica SpA',
        place: 'Napoli',
        phone: '+39 0823.14.43.161',
        mail: 'info@ioriotrasportielogistica.it',
        pec: '',
        website: 'www.ioriotrasportielogistica.it',
        fullAddress: 'Via San Giacomo, 40 80133 - Napoli',
        logo: 'gpl/companies/iorio.png',
        social: {
            linkedin: 'https://it.linkedin.com/company/iorio-trasporti-e-logistica-spa',
            instagram: '',
            facebook: 'https://www.facebook.com/IorioTrasportiELogistica/',
        },
        quotation: {
            position: 'CEO',
            name: 'Angelo Iorio',
            text: '"Aderire ad un gruppo che punta sulla green economy facendo squadra e condividendo lo stesso principio di innovazione e sostenibilità, diventa la ricetta ideale per un futuro progressista e longevo"',
            picture: 'gpl/companies/iorio-ceo.jpg',
            visible: false,
        },
        content: '/companies/iorio.html',
        certifications: [
        ],
        services: [
        ],
        stats: {
            veichles: 61,
            drivers: 62,
            infrastructures: 4,
        },
        partners: [
        ],
    },
    'so-log-srl': {
        slug: 'so-log-srl',
        name: 'So.Log. s.r.l.',
        place: 'Sondrio',
        phone: '',
        mail: 'info@solog.it',
        pec: '',
        website: 'www.solog.it',
        fullAddress: '',
        logo: 'gpl/companies/solog.jpg',
        social: {
            linkedin: '',
            instagram: '',
            facebook: 'https://www.facebook.com/sologtrasportielogistica/',
        },
        quotation: {
            position: 'CEO',
            name: '',
            text: '""',
            picture: 'gpl/user.png',
            visible: false,
        },
        content: '/companies/iorio.html',
        certifications: [
            'ISO 9001.2015',
        ],
        services: [
            'Logistica',
            'Raccolta e distribuzione',
            'Trasporti Intermodali',
            'Carichi completi',
            'Servizio espresso su pedana',
            'Servizi di collettame',
        ],
        stats: {
            veichles: 213,
            drivers: 70,
            infrastructures: 2,
        },
        partners: [
        ],
    },
    'lanzi-trasporti-srl': {
        slug: 'lanzi-trasporti-srl',
        name: 'Lanzi Trasporti s.r.l.',
        place: 'Parma',
        phone: '+39 0521 615021',
        mail: 'info@lanzitrasporti.it',
        pec: 'lanzitrasporti@legalmail.it',
        website: 'www.lanzitrasporti.it',
        fullAddress: 'Via Bonn 6, Interporto di Parma 43010 Bianconese di Fontevivo',
        logo: 'gpl/companies/Lanzi.png',
        social: {
            linkedin: 'https://www.linkedin.com/company/lanzi-trasporti-s.r.l./',
            instagram: '',
            facebook: 'https://www.facebook.com/lanzitrasporti/',
        },
        quotation: {
            position: 'CEO',
            name: '',
            text: '""',
            picture: '',
            visible: false,
        },
        content: '/companies/lanzi.html',
        certifications: [
            'ISO 9001.2015',
            'ISO 45001.2018',
        ],
        services: [
        ],
        stats: {
            veichles: 29,
            drivers: 25,
            infrastructures: 5,
        },
        partners: [
        ],
    },

    'mtl-montichiari-trasplog-srl': {
        slug: 'mtl-montichiari-trasplog-srl',
        name: 'M.T.L. Montichiari Trasp.Log. s.r.l.',
        place: 'Brescia',
        phone: '+39 030 9962004',
        mail: 'amministrazione@mtllogistica.it',
        pec: 'montichiaritrasportilogistica@pec.it',
        website: 'https://www.mtllogistica.com/mtl-logistica/la-storia/',
        fullAddress: 'Via Fermi, 56 - Montichiari (BS)',
        logo: 'gpl/companies/mtl.png',
        social: {
            linkedin: '',
            instagram: '',
            facebook: '',
        },
        quotation: {
            position: 'CEO',
            name: '',
            text: '""',
            picture: '',
            visible: false,
        },
        content: '/companies/mtl-trasporti.html',
        certifications: [
            'ISO 9001.2015',
        ],
        services: [
            'Logistica',
            'Raccolta e distribuzione',
            'Trasporti Internazionali',
            'Trasporto Intermodale',
            'Carichi completi',
            'Pallet espressi',
            'Trasporti speciali',
        ],
        stats: {
            veichles: 75,
            drivers: 59,
            infrastructures: 13,
        },
        partners: [
        ],
    },

    'rebasti-trasporti-sas': {
        slug: 'rebasti-trasporti-sas',
        name: 'Rebasti Trasporti s.a.s.',
        place: 'Nerviano',
        phone: '+39 335 7436388',
        mail: '',
        pec: 'rebastitrasporti@pec.it',
        website: 'http://rebastitrasporti.it/Default.aspx?ReturnUrl=%2f',
        fullAddress: 'Via Sempione, 27 - 20014 Nerviano (MI)',
        logo: 'gpl/companies/Rebasti.jpg',
        social: {
            linkedin: '',
            instagram: '',
            facebook: '',
        },
        quotation: {
            position: 'CEO',
            name: '',
            text: '""',
            picture: '',
            visible: false,
        },
        // content: '/companies/rebasti-trasporti.html',
        content: null,
        certifications: [
            'HACCP',
            'HACCP MANGIMI',
            'ADR',
        ],
        services: [
            'Logistica',
            'Raccolta e distribuzione',
            'Tasporti Internazionali',
            'Trasporto Intermodale',
            'Carichi completi',
            'Pallet espressi',
            'Trasporti speciali',
        ],
        stats: {
            veichles: 0,
            drivers: 0,
            infrastructures: 0,
        },
        partners: [
        ],
    },

    'racchetti-medio-trasporti': {
        slug: 'racchetti-medio-trasporti',
        name: 'Racchetti Medio Trasporti s.r.l.',
        place: 'Cremona',
        phone: '+39 0372 491021',
        mail: '',
        pec: 'racchettitrasporti@pec.it',
        website: 'https://www.racchettitrasporti.com/',
        fullAddress: 'Via delle Viole, 35 - 26100 Cremona (CR)',
        logo: 'gpl/companies/racchetti.jpg',
        social: {
            linkedin: '',
            instagram: '',
            facebook: '',
        },
        quotation: {
            position: 'CEO',
            name: '',
            text: '""',
            picture: '',
            visible: false,
        },
        // content: '/companies/racchetti-medio-trasporti.html',
        content: null,
        certifications: [
            'HACCP',
            'HACCP MANGIMI',
            'ADR',
        ],
        services: [
            'Logistica',
            'Raccolta e distribuzione',
            'Tasporti Internazionali',
            'Trasporto Intermodale',
            'Carichi completi',
            'Pallet espressi',
            'Trasporti speciali',
        ],
        stats: {
            veichles: 0,
            drivers: 0,
            infrastructures: 0,
        },
        partners: [
        ],
    },

    'bianchi-modena-srl': {
        slug: 'bianchi-modena-srl',
        name: 'Bianchi Modena s.r.l.',
        place: 'Modena',
        phone: '+39 031 566811',
        mail: 'salesoffice@bianchigroup.com',
        pec: 'bianchimodena@pec.it',
        website: 'https://www.bianchitrasporti-it.com/',
        fullAddress: 'Via dei Maniscalchi, 10/12 - 41012 Carpi (MO)',
        logo: 'gpl/companies/bianchi.jpg',
        social: {
            linkedin: '',
            instagram: '',
            facebook: '',
        },
        quotation: {
            position: 'CEO',
            name: '',
            text: '""',
            picture: '',
            visible: false,
        },
        // content: '/companies/bianchi-group.html',
        content: null,
        certifications: [
            'HACCP',
            'ADR',
        ],
        services: [
            'Logistica',
            'Raccolta e distribuzione',
            'Tasporti Internazionali',
            'Trasporto Intermodale',
            'Carichi completi',
            'Pallet espressi',
            'Trasporti speciali',
        ],
        stats: {
            veichles: 0,
            drivers: 0,
            infrastructures: 0,
        },
        partners: [
        ],
    },

    'bordignon-trasporti-srl': {
        slug: 'bordignon-trasporti-srl',
        name: 'Bordignon Trasporti s.r.l.',
        place: 'Torino',
        phone: '+39 031 566811',
        mail: 'salesoffice@bianchigroup.com',
        pec: 'bordignontrasporti@pec.it',
        website: 'https://www.bianchitrasporti-it.com/',
        fullAddress: 'Via dei Maniscalchi, 10/12 - 41012 Carpi (MO)',
        logo: 'gpl/companies/bordignon.jpg',
        social: {
            linkedin: '',
            instagram: '',
            facebook: '',
        },
        quotation: {
            position: 'CEO',
            name: '',
            text: '""',
            picture: '',
            visible: false,
        },
        // content: '/companies/bianchi-group.html',
        content: null,
        certifications: [
            'ISO 9001-2015',
        ],
        services: [
            'Logistica',
            'Raccolta e distribuzione',
            'Tasporti Internazionali',
            'Trasporto Intermodale',
            'Carichi completi',
            'Pallet espressi',
            'Trasporti speciali',
        ],
        stats: {
            veichles: 0,
            drivers: 0,
            infrastructures: 0,
        },
        partners: [
        ],
    },

    'vallin-autotrasporti-srl': {
        slug: 'vallin-autotrasporti-srl',
        name: 'Vallin Autorasporti s.r.l.',
        place: 'Vigevano',
        phone: '+39 031 566811',
        mail: 'salesoffice@bianchigroup.com',
        pec: 'bordignontrasporti@pec.it',
        website: 'https://www.bianchitrasporti-it.com/',
        fullAddress: 'Via Tortona, 34/10 - 27029 Bressana Bottarone (PV)',
        logo: 'gpl/companies/vallin.jpg',
        social: {
            linkedin: '',
            instagram: '',
            facebook: '',
        },
        quotation: {
            position: 'CEO',
            name: '',
            text: '""',
            picture: '',
            visible: false,
        },
        // content: '/companies/bianchi-group.html',
        content: null,
        certifications: [
            'ISO 9001-2015',
        ],
        services: [
            'Logistica',
            'Raccolta e distribuzione',
            'Tasporti Internazionali',
            'Trasporto Intermodale',
            'Carichi completi',
            'Pallet espressi',
            'Trasporti speciali',
        ],
        stats: {
            veichles: 0,
            drivers: 0,
            infrastructures: 0,
        },
        partners: [
        ],
    },
};

export default COMPANIES;
